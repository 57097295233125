import React, { useEffect, useState } from "react";
import sendImage from "../asset/images/send.png"; // Importing the send.png image
import logoImage from "../asset/images/logo.png"; // Importing the logo.png image
import Loader from "./Loader";
import { json, useNavigate } from "react-router";
import citiZenShipIconWithText from "../asset/images/Logo111.png";
import Geolocation from "react-geolocation";
const Explore = () => {
  const navigator = useNavigate();
  const [data, setData] = useState();
  const [ipAddress, setIpAddress] = useState("");
  const [location, setLocation] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState(null);
  const [userLocation, setUserLocation] = useState(null);

  const handleLocation = (position) => {
    setUserLocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };
  useEffect(() => {
    const websiteId = 110; // Replace with your actual website ID
    const code = "LdkDKw6YH8ML9hs5hwW1rnePJqTWARhOWZa4hbI18UOAAzFu5iwdvA=="; // Replace with your actual code
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://citizencopilotchat.azurewebsites.net/api/bot/init?website_id=${websiteId}&code=${code}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json", // Assuming JSON response
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.status}`);
        }

        const responseData = await response.json();
        console.log(responseData);
        setData(responseData.data);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    const fetchIp = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
        console.log(ipAddress);
      } catch (error) {
        console.error("Error fetching IP:", error);
        setError("Failed to fetch IP address.");
      }
    };

    // const fetchLocation = async () => {
    //   try {
    //     // Access user location with consent (if applicable)
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //           const latitude = position.coords.latitude;
    //           const longitude = position.coords.longitude;
    //           console.log(latitude, longitude);
    //           setLocation({ latitude: latitude, longitude: longitude });
    //         },
    //         (error) => {
    //           console.error("Error accessing user geolocation:", error);
    //           setError("Failed to access user location.");
    //         }
    //       );
    //     } else {
    //       console.error("Geolocation is not supported by this browser.");
    //       setError("Geolocation not supported.");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching location:", error);
    //     setError("Failed to fetch location data.");
    //   }
    // };
    fetchIp();
    // fetchLocation();
  }, []);

  const preDefinedquestions = (content) => {
    setQuery(content);
    handleSubmit();
  };
  const handleSubmit = () => {
    if (query) {
      localStorage.setItem("query", query);
      localStorage.setItem("ip", JSON.stringify(ipAddress));
      localStorage.setItem("location", JSON.stringify(userLocation));
      navigator("/chat");
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div style={{ display: "none" }}>
            <Geolocation
              onSuccess={handleLocation}
              render={({ fetchingPosition, position, error }) => (
                <div>
                  {error && <p>Error: {error.message}</p>}
                  {fetchingPosition ? (
                    <p></p>
                  ) : (
                    <div>
                      <p></p>
                      {position && <p></p>}
                    </div>
                  )}
                </div>
              )}
            />
          </div>

          <section className="topMenuSec lightWhiteColor">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12">
                  <div
                    className="logoMenu m-auto"
                    onClick={() => navigator("/")}
                  >
                    <img
                      src={citiZenShipIconWithText}
                      alt="Logo"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="chatMainSec lightWhiteColor chatbsck pb-0">
            <div className="container backChatIner">
              <div className="row">
                <div className="col-12 alignPadding">
                  <div className="text-center">
                    <h3 className="veryBigFont fontWeightlight mb-4">
                      {data?.title}
                    </h3>
                    <h5 className="font-weight400">{data?.description}</h5>
                  </div>
                </div>
              </div>

              <div className="row pb-5 ">
                <div className="col-lg-4 mt-5 mt-md-0 ">
                  <div className="eachDivInerSec">
                    <div className="eachSecInerContent text-center">
                      <h4 className="pb-2 d-inline-block m-auto">
                        <img src={data?.questions.iconUrl} alt="" />
                        {data?.questions?.title}
                      </h4>
                    </div>
                    {data?.questions?.jsonList.map((content, index) => (
                      <div
                        className="inerTetDiv mt-3 shadow-sm inerDivBackGredient"
                        key={content + index}
                      >
                        <div
                          className="d-flex align-items-center "
                          onClick={() => preDefinedquestions(content)}
                        >
                          <h5 className="smallFntInr pe-3 mb-0">{content}</h5>
                          <div className="rightIcon">
                            <i className="fas fa-chevron-right"></i>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 mt-5 mt-md-0">
                  <div className="eachDivInerSec">
                    <div className="eachSecInerContent text-center">
                      <h4 className="pb-2 d-inline-block m-auto">
                        <img src={data?.capabilities.iconUrl} alt="" />
                        {data?.capabilities?.title}
                      </h4>
                    </div>
                    {data?.capabilities?.jsonList.map((content, index) => (
                      <div
                        className="inerTetDiv mt-3 shadow-sm"
                        key={content + index}
                      >
                        <div
                          className="d-flex align-items-center "
                          onClick={() => preDefinedquestions(content)}
                        >
                          <h5 className="smallFntInr pe-3 mb-0">{content}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-lg-4 mt-5 mt-md-0">
                  <div className="eachDivInerSec">
                    <div className="eachSecInerContent text-center">
                      <h4 className="pb-2 d-inline-block m-auto">
                        <img src={data?.limitations.iconUrl} alt="" />
                        {data?.limitations?.title}
                      </h4>
                    </div>
                    {data?.limitations?.jsonList.map((content, index) => (
                      <div
                        className="inerTetDiv mt-3 shadow-sm"
                        key={content + index}
                      >
                        <div
                          className="d-flex align-items-center "
                          onClick={() => preDefinedquestions(content)}
                        >
                          <h5 className="smallFntInr pe-3 mb-0">{content}</h5>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-sm-12 m-auto">
                  <div className="row pb-4">
                    <div className="col-12 pt-3">
                      <div className="row">
                        <div className="col-10 pe-0">
                          <div className="chatInputSec">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control shadow-sm"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="Type your question..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" || e.keyCode === 13) {
                                    return handleSubmit();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="chatBtn">
                            <button
                              type="submit"
                              className="btn btn-primary mb-2 shadow"
                              onClick={handleSubmit}
                            >
                              <img
                                src={sendImage}
                                alt="Send"
                                className="img-fluid"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Explore;
