import React from "react";
import logo from "../asset/images/logo.png"; // Import the image (replace with actual path)
import chatBackImg from "../asset/images/chatBack.png"; // Import the image (replace with actual path)
const Loader = () => {
  return (
    <>
      <section className="topMenuSec lightWhiteColor">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="logoMenu m-auto">
                <img src={logo} alt="Company Logo" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="chatMainSec lightWhiteColor heightSec alignPadding pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                <div className="loaderImage">
                  <figure>
                    <img
                      src={chatBackImg}
                      className="img-fluid"
                      alt="Chat Background"
                    />
                  </figure>
                  <figcaption className="text-center">
                    <h3 className="fontWeightMiduamlight">
                      Please wait, We're{" "}
                      <span className="fontWeightSemiboldLight loadingBlue">
                        fetching
                      </span>{" "}
                      this for you
                    </h3>
                    <p className="mt-4">API Collection progress - 80%</p>
                  </figcaption>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Loader;
