import React, { useEffect, useState, useRef } from "react";
import logo from "../asset/images/logo.png";
import userImage from "../asset/images/user 1.png";
import wikipediaLogo from "../asset/images/Wikipedia-logo-v2.svg 1.png";
import leftArrow from "../asset/images/left.png";
import sendImage from "../asset/images/send.png";
import loaderImage from "../asset/images/loader.gif";
import loadingChat from "../asset/images/loadingImage.png";
import citiZenShipIcon from "../asset/images/citizenshipIcon.png";
import citiZenShipIconWithText from "../asset/images/Logo111.png";
import { useNavigate } from "react-router";
const PreviousChatHistory = ({
  history,
  searchPreviousQuery,
  searchNewQuestion,
}) => {
  const navigate = useNavigate();
  const handleClick = (content) => {
    searchPreviousQuery(content);
  };
  const newQuestion = () => {
    searchNewQuestion();
    sessionStorage.setItem("searchHistory", null);
    console.log("-0987");
  };
  return (
    <div className="col-lg col-sm-12 mobileNone">
      <div className="listMenuSec">
        <ul className="list-unstyled">
          <li className="cursor-pointer d-flex" onClick={() => newQuestion()}>
            <div className="pe-2 editIcon"><i class="fas fa-pencil-alt"></i></div>
            <div>Start a new question</div>
          </li>
          {history?.map((content, index) => (
            <li className="cursor-pointer d-flex" key={index} onClick={() => handleClick(content)}>
              <div className="pe-2 editIcon"><i className="fas fa-pencil-alt"></i> </div>
              <div>{content}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
function removeProtocol(url) {
  return url.replace(/^https:\/\//, "");
}
const RelevantLink = ({ query, links }) => {
  console.log(links);
  return (
    <div className="col-lg-3 col-md-12 order-md-1 mobileNone">
      <div className="recentLinks">
        <h5 className="borderBottom pb-2 mb-3 pt-2">Relevant links</h5>
      </div>
      <div className="listMenuSec iconList">
        <h6>{query}</h6>
        <ul className="list-unstyled mt-3">
          {links?.map((content) => (
            <li className="shadow">
              <a href={content} target="_blank">
                <span className="d-inline-block">
                  <img src={leftArrow} className="img-fluid" alt="Left Arrow" />
                </span>
                {content}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="shareList mt-4">
        <h6 className="smallFont ">
          <a href="" className="textColor">
            this conversation with links
            <span className="ps-3">
              <i className="fas fa-share-alt"></i>
            </span>
          </a>
        </h6>
      </div>
    </div>
  );
};
const Chat = () => {
  const containerRef = useRef(null); // Ref for the scrollable container
  const lastDivRef = useRef(null); // Ref for the last div

  const [history, setHistory] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);
  const [ip, setIp] = useState();
  const [searchQuery, setSearchQuery] = useState(null);
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (containerRef.current && lastDivRef.current) {
      // Calculate the scroll position to ensure the last div is visible
      const scrollTop = containerRef.current.scrollTop;
      const containerHeight = containerRef.current.clientHeight;
      const lastDivTop = lastDivRef.current.offsetTop;
      const lastDivHeight = lastDivRef.current.clientHeight;

      const scrollNeeded = lastDivTop - containerHeight + lastDivHeight; // Calculate required scroll

      // Only scroll if necessary (prevents unnecessary scrolling)
      if (scrollTop < scrollNeeded) {
        containerRef.current.scrollTo({
          top: scrollNeeded,
          behavior: "smooth", // Smooth scrolling
        });
      }

      // Set focus on the last div after scrolling (optional)
      lastDivRef.current.focus(); // Focus on the last div (e.g., for input)
    }
  }, [searchHistory, containerRef, lastDivRef]); // Re-run effect on content change

  const handleSubmitQuery = async (previousQuery = null) => {
    console.log("--------");
    if (previousQuery != null) {
      console.log(previousQuery);
      setCurrentQuery(previousQuery);
    } else if (searchQuery) {
      setCurrentQuery(searchQuery);
    }
    if (!searchQuery && !previousQuery) {
      return;
    }
  };
  useEffect(() => {
    if (!firstRender) {
      return;
    }
    const existingQuery = JSON.parse(
      sessionStorage.getItem("queryHistory") || "[]"
    );
    if (existingQuery.includes(currentQuery)) {
    } else {
      if (currentQuery != null) {
        const updatedQueryArray = [...existingQuery, currentQuery];
        sessionStorage.setItem(
          "queryHistory",
          JSON.stringify(updatedQueryArray)
        );
        setHistory(updatedQueryArray);
      }
    }
    const callApi = async () => {
      setSearchQuery("");
      const obj = {};
      obj[currentQuery] = null;
      setSearchHistory([...searchHistory, obj]);
      const userLocation = JSON.parse(localStorage.getItem("location"));
      try {
        const response = await fetch(
          "https://qubot.azurewebsites.net/api/bot/query",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              website_id: 110,
              query: currentQuery,
              source: "web",
              session_id: sessionId,
              ip_address: ip,
              latitude: userLocation.latitude,
              longitude: userLocation.longitude,
            }),
          }
        );
        console.log(response);
        if (!response.ok) {
          throw new Error(`API call failed with status ${response.status}`);
        }

        const data = await response.json();
        setData(data);
        obj[currentQuery] = data;
        console.log("-0990", [...searchHistory, obj]);
        setSearchHistory([...searchHistory, obj]);
        console.log();
        sessionStorage.setItem(
          "searchHistory",
          JSON.stringify([...searchHistory, obj])
        );
        console.log(searchHistory);
      } catch (error) {
        console.error("Error fetching age requirement:", error);
        setError("Failed to retrieve license age requirement.");
      }
    };
    if (currentQuery) {
      callApi();
    }
  }, [currentQuery]);
  useEffect(() => {
    const fetchQuery = async (query, ip, longitude, latitude) => {
      const obj = {};
      obj[query] = null;
      setSearchHistory([...searchHistory, obj]);
      //   setCurrentQuery(searchQuery);
      try {
        const response = await fetch(
          "https://qubot.azurewebsites.net/api/bot/query",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              website_id: 110,
              query: query,
              source: "web",
              session_id: null,
              ip_address: ip,
              latitude: latitude,
              longitude: longitude,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`API call failed with status ${response.status}`);
        }

        const data = await response.json();
        setData(data);
        obj[query] = data;
        setSearchHistory([...searchHistory, obj]);
        setFirstRender(true);
        console.log(searchHistory, [obj], query);
        sessionStorage.setItem(
          "searchHistory",
          JSON.stringify([...searchHistory, obj])
        );
        setSessionId(data.session_id);
        console.log(data);
      } catch (error) {
        console.error("Error fetching age requirement:", error);
        setError("Failed to retrieve license age requirement.");
      }
    };

    if (localStorage.getItem("query")) {
      const queryValue = localStorage.getItem("query");
      const existingQuery = JSON.parse(
        sessionStorage.getItem("queryHistory") || "[]"
      );
      const updatedQueryArray = [...existingQuery, queryValue];
      sessionStorage.setItem("queryHistory", JSON.stringify(updatedQueryArray));

      fetchQuery(
        queryValue,
        JSON.parse(localStorage.getItem("ip")),
        null,
        null
      );
      localStorage.removeItem("query");
      setHistory(JSON.parse(sessionStorage.getItem("queryHistory")));
      console.log("Session storage:", sessionStorage);
      setCurrentQuery(queryValue);
    } else if (sessionStorage.getItem("queryHistory")) {
      const queryHistory = JSON.parse(sessionStorage.getItem("queryHistory"));
      const lastQuery = queryHistory[queryHistory.length - 1];
      setCurrentQuery(lastQuery);
      setIp(JSON.parse(localStorage.getItem("ip")));
      setHistory(JSON.parse(sessionStorage.getItem("queryHistory")));
      console.log(
        "------------++++++++++++++++",
        JSON.parse(sessionStorage.getItem("searchHistory"))
      );
      if (
        JSON.parse(sessionStorage.getItem("searchHistory")) &&
        JSON.parse(sessionStorage.getItem("searchHistory")).length !== 0
      ) {
        let PreviousChatHistoryData = JSON.parse(
          sessionStorage.getItem("searchHistory")
        );
        console.log(
          "------------++++++++++++++++",
          JSON.parse(sessionStorage.getItem("searchHistory"))
        );
        setSearchHistory([...PreviousChatHistoryData]);
      } else {
        fetchQuery(
          lastQuery,
          JSON.parse(localStorage.getItem("ip")),
          null,
          null
        );
      }
    } else {
      console.log("Query key does not exist in local storage.");
    }
  }, []);
  const navigate = useNavigate();
  const handleClearSearchHistory = () => {
    setSearchHistory([]);
    setCurrentQuery(null);
    setData(null);
    console.log("--------------");
  };
  return (
    <>
      <section className="topMenuSec lightWhiteColor">
        <div className="container">
          <div
            className="row align-items-center"
            onClick={() => navigate("/explore")}
          >
            <div className="col-12">
              <div className="logoMenu m-auto">
                <img
                  src={citiZenShipIconWithText}
                  alt="Company Logo" // Provide a descriptive alt text
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="chatMainSec lightWhiteColor py-1 pb-0">
        <div className="container backChatIner">
          <div className="row">
            <PreviousChatHistory
              history={history}
              searchPreviousQuery={handleSubmitQuery}
              searchNewQuestion={handleClearSearchHistory}
            />
            <RelevantLink query={currentQuery} links={data?.reference} />
            <div className="col-lg-6 col-md-12">
              <div className="row  pb-4">
                <div className="col-12">
                  <div
                    ref={containerRef}
                    className="chatSection row align-items-end"
                  >
                    <div className="col-12">
                      {searchHistory?.map((value, index) => {
                        for (const key in value) {
                          var valueData = value[key];
                          var keyData = key;
                        }
                        return (
                          <>
                            <div
                              className="rightSendMessage text-end"
                              key={index}
                              ref={
                                index === searchHistory.length - 1
                                  ? lastDivRef
                                  : null
                              }
                            >
                              <div className="rightUserSec d-inline-block">
                                <figcaption>
                                  <p className="m-0">{keyData}</p>
                                </figcaption>
                                <div className="profileImageChat ms-auto">
                                  <img
                                    src={userImage}
                                    className="img-fluid"
                                    alt="User"
                                  />
                                </div>
                              </div>
                            </div>

                            {valueData?.answer ? (
                              <div className="leftComeingMessage">
                                <div className="leftSendMessageSec mb-4">
                                  <figcaption className="shadow">
                                    <p className="m-0">{valueData?.answer}</p>
                                  </figcaption>
                                  {valueData?.answer && (
                                    <div className="d-flex justify-content-between">
                                      <div className="profileImageChat">
                                        <div className=" mt-1">
                                          <div className="d-flex align-items-center">
                                            <img
                                              src={citiZenShipIcon}
                                              className="img-fluid me-2"
                                              alt="User"
                                            />
                                            Citizen Copilot
                                          </div>
                                          <div className="inerLink">
                                            {valueData?.reference?.map(
                                              (link) => (
                                                <div className="d-inline-block inerLinkContentDiv">
                                                  <h6 className="smallFont ">
                                                    <span className="leftLinkIcon">
                                                      <i className="fas fa-link"></i>
                                                    </span>
                                                    <a
                                                      href={link}
                                                      className="textColor"
                                                      target="_blank"
                                                    >
                                                      {removeProtocol(link)}
                                                    </a>
                                                  </h6>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="">
                                        <div className="d-flex mt-1">
                                          <button
                                            className="floatBtnRounded btn ms-2 shadow-sm"
                                            onClick={() =>
                                              navigator.clipboard.writeText(
                                                valueData?.answer
                                              )
                                            }
                                          >
                                            <i className="far fa-copy"></i>
                                          </button>
                                          <button className="floatBtnRounded btn ms-2 shadow-sm">
                                            <i className="far fa-thumbs-up"></i>
                                          </button>
                                          <button className="floatBtnRounded shadow-sm btn ms-2">
                                            <i className="fas fa-reply"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex align-items-center mb-1">
                                <img
                                  src={citiZenShipIcon}
                                  className="img-fluid me-1 loadingChatImage"
                                  alt="User"
                                />
                                Citizen Copilot processing  
                                {/* loaderImage */}
                                <img
                                  src={loaderImage}
                                  className="img-fluid me-1"
                                  alt="User"
                                />
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-12 pt-3">
                    <div className="row">
                      <div className="col-10 pe-0">
                        <div className="chatInputSec">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control shadow-sm"
                              id="exampleInputEmail1"
                              placeholder="Type your question..."
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter" || e.keyCode === 13) {
                                  return handleSubmitQuery();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="chatBtn">
                          <button
                            type="submit"
                            className="btn btn-primary mb-2 shadow"
                            onClick={() => handleSubmitQuery()}
                          >
                            <img
                              src={sendImage}
                              className="img-fluid"
                              alt="Send"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="mb-0 mt-2 ps-4 verysmallFont">
                          Citizen Copilot can make mistakes. Consider checking
                          important information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chat;
