import React, { useEffect, useState, useRef } from "react";
import logoImage from "../asset/images/logo.png";
import userImage from "../asset/images/user 1.png";
import sendImage from "../asset/images/send.png";
import openImage from "../asset/images/clickOpen.png";
import citiZenShipIconWithText from "../asset/images/Logo111.png";
const CustomIntegration = () => {
  const containerRef = useRef(null);
  const lastDivRef = useRef(null);
  const [history, setHistory] = useState();
  const [data, setData] = useState();
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [currentQuery, setCurrentQuery] = useState(null);
  const [searchHistory, setSearchHistory] = useState([]);
  const [ip, setIp] = useState();
  const [searchQuery, setSearchQuery] = useState(null);
  const [firstRender, setFirstRender] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleSubmitQuery = async (previousQuery = null) => {
    if (previousQuery != null) {
      console.log(previousQuery);
      setCurrentQuery(previousQuery);
    } else {
      setCurrentQuery(searchQuery);
    }
    if (!searchQuery && !previousQuery) {
      return;
    }
  };
  useEffect(() => {
    if (containerRef.current && lastDivRef.current) {
      // Calculate the scroll position to ensure the last div is visible
      const scrollTop = containerRef.current.scrollTop;
      const containerHeight = containerRef.current.clientHeight;
      const lastDivTop = lastDivRef.current.offsetTop;
      const lastDivHeight = lastDivRef.current.clientHeight;

      const scrollNeeded = lastDivTop - containerHeight + lastDivHeight; // Calculate required scroll

      // Only scroll if necessary (prevents unnecessary scrolling)
      if (scrollTop < scrollNeeded) {
        containerRef.current.scrollTo({
          top: scrollNeeded,
          behavior: "smooth", // Smooth scrolling
        });
      }

      // Set focus on the last div after scrolling (optional)
      lastDivRef.current.focus(); // Focus on the last div (e.g., for input)
    }
  }, [searchHistory, containerRef, lastDivRef]); // Re-run effect on content change
  useEffect(() => {
    if (!firstRender) {
      return;
    }
    console.log("test-----");
    const existingQuery = JSON.parse(
      sessionStorage.getItem("queryHistory") || "[]"
    );
    if (existingQuery.includes(currentQuery)) {
    } else {
      const updatedQueryArray = [...existingQuery, currentQuery];
      sessionStorage.setItem("queryHistory", JSON.stringify(updatedQueryArray));
      setHistory(updatedQueryArray);
    }
    const callApi = async () => {
      setSearchQuery("");
      const obj = {};
      obj[currentQuery] = null;
      setSearchHistory([...searchHistory, obj]);
      try {
        const response = await fetch(
          "https://qubot.azurewebsites.net/api/bot/query",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              website_id: 110,
              query: currentQuery,
              source: "web",
              session_id: sessionId,
              ip_address: ip,
              latitude: null,
              longitude: null,
            }),
          }
        );
        console.log(response);
        if (!response.ok) {
          throw new Error(`API call failed with status ${response.status}`);
        }

        const data = await response.json();
        setData(data);
        obj[currentQuery] = data;
        console.log("-0990", [...searchHistory, obj]);
        setSearchHistory([...searchHistory, obj]);
        console.log();
        sessionStorage.setItem(
          "searchHistory",
          JSON.stringify([...searchHistory, obj])
        );
        console.log(searchHistory);
      } catch (error) {
        console.error("Error fetching age requirement:", error);
        setError("Failed to retrieve license age requirement.");
      }
    };
    callApi();
  }, [currentQuery]);
  useEffect(() => {
    setFirstRender(true);
    const fetchQuery = async (query, ip, longitude, latitude) => {
      const obj = {};
      obj[query] = null;
      setSearchHistory([...searchHistory, obj]);
      //   setCurrentQuery(searchQuery);
      try {
        const response = await fetch(
          "https://qubot.azurewebsites.net/api/bot/query",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              website_id: 110,
              query: query,
              source: "web",
              session_id: null,
              ip_address: ip,
              latitude: latitude,
              longitude: longitude,
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`API call failed with status ${response.status}`);
        }

        const data = await response.json();
        setData(data);
        obj[query] = data;
        setSearchHistory([...searchHistory, obj]);

        console.log(searchHistory, [obj], query);
        sessionStorage.setItem(
          "searchHistory",
          JSON.stringify([...searchHistory, obj])
        );
        setSessionId(data.session_id);
        console.log(data);
      } catch (error) {
        console.error("Error fetching age requirement:", error);
        setError("Failed to retrieve license age requirement.");
      }
    };

    if (sessionStorage.getItem("queryHistory")) {
      const queryHistory = JSON.parse(sessionStorage.getItem("queryHistory"));
      const lastQuery = queryHistory[queryHistory.length - 1];
      setCurrentQuery(lastQuery);
      setIp(JSON.parse(localStorage.getItem("ip")));
      setHistory(JSON.parse(sessionStorage.getItem("queryHistory")));
      console.log(
        "------------++++++++++++++++",
        JSON.parse(sessionStorage.getItem("searchHistory"))
      );
      if (
        JSON.parse(sessionStorage.getItem("searchHistory")) &&
        JSON.parse(sessionStorage.getItem("searchHistory")).length !== 0
      ) {
        let PreviousChatHistoryData = JSON.parse(
          sessionStorage.getItem("searchHistory")
        );
        console.log(
          "------------++++++++++++++++",
          JSON.parse(sessionStorage.getItem("searchHistory"))
        );
        setSearchHistory([...PreviousChatHistoryData]);
      } else {
        fetchQuery(
          lastQuery,
          JSON.parse(localStorage.getItem("ip")),
          null,
          null
        );
      }
    } else {
      console.log("Query key does not exist in local storage.");
    }
  }, []);
  return (
    <>
      <div className="bottomFixedBtn" onClick={() => setShowModal(!showModal)}>
        <img src={openImage} className="img-fluid" alt="Open" />
      </div>
      {showModal && (
        <div className="shadow bottomchatSec showModal lightWhiteColor p-4 py-2">
          <div className="col-md-12 col-sm-12">
            <div className="row">
              <div className="col">
                <div className="d-flex align-items-center">
                  <div className="chamodalLogo">
                    <img src={logoImage} className="img-fluid" alt="Logo" />
                  </div>
                  <div className="mx-3">
                    <p className="mb-0 verysmallFont">
                      Share this conversation with links
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 verysmallFont">
                      <i className="fas fa-share-alt"></i>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="text-end rightSideIconModal">
                  <div className="d-inline-block px-2">
                    <p className="mb-0 verysmallFont">
                      <i className="fas fa-expand-alt"></i>
                    </p>
                  </div>
                  <div className="d-inline-block px-2 closeChatModal">
                    <p
                      className="mb-0 verysmallFont"
                      onClick={() => setShowModal(false)}
                    >
                      <i className="fas fa-times"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row  pb-4">
              <div className="col-12">
                <div
                  className="chatSection row align-items-end"
                  ref={containerRef}
                >
                  <div className="col-12">
                    {searchHistory &&
                      searchHistory?.map((value, index) => {
                        for (const key in value) {
                          var valueData = value[key];
                          var keyData = key;
                        }
                        return (
                          <>
                            <div
                              className="rightUserSec d-inline-block"
                              key={index}
                              ref={
                                index === searchHistory.length - 1
                                  ? lastDivRef
                                  : null
                              }
                            >
                              <figcaption>
                                <p className="m-0">{keyData}</p>
                              </figcaption>
                              <div className="profileImageChat ms-auto">
                                <img
                                  src={userImage}
                                  className="img-fluid"
                                  alt="User"
                                />
                              </div>
                            </div>
                            <div className="leftComeingMessage">
                              <div className="leftSendMessageSec mb-4">
                                <figcaption className="shadow">
                                  <p className="m-0">
                                    {valueData?.answer ||
                                      "Citizen Copilot processing"}
                                  </p>
                                </figcaption>
                                <div className="d-flex justify-content-between">
                                  <div className="profileImageChat">
                                    <img
                                      src={userImage}
                                      className="img-fluid"
                                      alt="User"
                                    />
                                  </div>
                                  <div className="d-flex mt-1">
                                    <button className="floatBtnRounded btn ms-2 shadow-sm">
                                      <i className="far fa-copy"></i>
                                    </button>
                                    <button className="floatBtnRounded btn ms-2 shadow-sm">
                                      <i className="far fa-thumbs-up"></i>
                                    </button>
                                    <button className="floatBtnRounded shadow-sm btn ms-2">
                                      <i className="fas fa-reply"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 pt-3">
                  <div className="row">
                    <div className="col-10 pe-0">
                      <div className="chatInputSec">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control shadow-sm"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Type your question..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.keyCode === 13) {
                                return handleSubmitQuery();
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="chatBtn">
                        <button
                          type="submit"
                          className="btn btn-primary mb-2 shadow"
                          onClick={() => handleSubmitQuery()}
                        >
                          <img
                            src={sendImage}
                            className="img-fluid"
                            alt="Send"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomIntegration;
