import React, { useState } from "react";
import logo from "../asset/images/logo.png"; // Assuming the image file is located in the same directory as this component
import artwork from "../asset/images/artWork.png"; // Assuming the image file is located in the same directory as this component
import bannerImage from "../asset/images/Banner.png"; // Assuming the image file is located in the same directory as this component
import secondImage from "../asset/images/secondImage.png"; // Assuming the image file is located in the same directory as this component
import thirdImage from "../asset/images/thirdImage.png"; // Assuming the image file is located in the same directory as this component
import fourthImage from "../asset/images/fourth.png"; // Assuming the image file is located in the same directory as this component
import logoWhite from "../asset/images/logoWhite.png"; // Assuming the image file is located in the same directory as this component
import { Link } from "react-router-dom";
import citiZenShipIconWithText from "../asset/images/Logo111.png";
import textMessagesRemix from "../asset/gif/Text-messages-[remix].gif";
const Home = () => {
  const [faqs, setFaqs] = useState([
    {
      question:
        "How can Citizen Copilot help me with government services and benefits?",
      answer:
        "Citizen Copilot provides you with easy-to-use chatbot assistance to navigate through government services and benefits. Whether you're looking for information on food stamps, Medicaid, or any other program, our chatbot is here to guide you step by step, making the process as straightforward as possible.",
      show: false,
    },
    {
      question: "Is there a cost to use Citizen Copilot?",
      answer:
        "No, Citizen Copilot is offered free of charge to users. Our mission is to ensure that you have the support you need to access government benefits and services without any financial burden.",
      show: false,
    },
    {
      question: "How do I start a chat with Citizen Copilot?",
      answer:
        "Starting a chat with Citizen Copilot is simple. Visit our website and look for the chat window on the bottom right corner of the page. Just click on it and type your question to begin. Our chatbot is available 24/7 to assist you.",
      show: false,
    },
    {
      question: "Can Citizen Copilot assist me if I have a disability?",
      answer:
        "Absolutely. Citizen Copilot is designed with accessibility in mind, ensuring ADA compliance and making our content and features fully accessible to individuals with disabilities. If you have specific needs or require assistance, please do not hesitate to reach out through our chatbot.",
      show: false,
    },
    {
      question: "What kind of questions can I ask Citizen Copilot?",
      answer:
        "You can ask any questions related to government services and benefits you're interested in or eligible for. This includes inquiries about eligibility criteria, application processes, required documentation, and updates on existing applications. Our chatbot is programmed to provide comprehensive support on these topics.",
      show: false,
    },
  ]);

  const toggleShow = (index) => {
    // Create a copy of the faqs array to avoid mutation
    const updatedFaqs = [...faqs];

    // Update the show property of the clicked FAQ
    updatedFaqs[index].show = !updatedFaqs[index].show;

    // Update the state with the modified array
    setFaqs(updatedFaqs);
  };
  return (
    <>
      <section className="topMenuSec whiteBack">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-sm-4">
              <div className="logoMenu">
                <img
                  src={citiZenShipIconWithText}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-6 col-sm-8 text-end">
              <div className="menuLink">
                <ul className="list-unstyled d-flex me-3"></ul>
              </div>
              <p className="d-inline-block m-0">
                <p className="btnTheme m-0">CONTACT US</p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="banerSection alignPadding  pt-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="banerBigtext">
                <h3 className="veryBigFont fontWeightlight">Guiding You to</h3>
                <h3 className="veryBigFont fontWeightSemibolder">
                  Your Benefits
                </h3>
                <p className="mt-3 smallFont textColor">
                  Find personalized assistance for all your government service
                  needs, easily accessible with just a simple message. Here to
                  help, every step of the way.
                </p>
                <p className="gredientBtn pt-2">
                  <p>
                    <Link to="/explore">EXPERIENCE IT</Link>
                  </p>
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="bannerImage">
                <figure>
                  <img src={artwork} className="img-fluid" alt="Artwork" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="backSecond">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <img
                src={bannerImage}
                className="img-fluid m-auto"
                alt="Banner"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="banerSection alignPadding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="bannerImage">
                <figure>
                  <img
                    src={secondImage}
                    className="img-fluid"
                    alt="Second Image"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-5 order-md-first">
              <div className="banerBigtext">
                <h3 className="veryBigFont fontWeightlight">
                  Getting Answers is as Easy as{" "}
                  <span className="fontWeightSemiboldLight">
                    Sending a Text
                  </span>
                  Message
                </h3>
                <p className="mt-3 smallFont textColor">
                  Effortlessly connect with the information you need. Experience
                  the convenience of instant replies, right at your fingertips
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banerSection alignPadding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="bannerImage thirdSmallImage">
                <figure>
                  <img
                    src={textMessagesRemix}
                    className="img-fluid"
                    alt="Third Image"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-5">
              <div className="banerBigtext">
                <h3 className="veryBigFont fontWeightlight">
                  Let’s Find{" "}
                  <span className="fontWeightSemiboldLight">
                    Relevance Information
                  </span>{" "}
                  at Your Own Pace.
                </h3>
                <p className="mt-3 smallFont textColor">
                  Discover tailored content that matches your interests and
                  schedule. Dive into a world of knowledge, curated just for you
                </p>
                <p className="mt-3 smallFont textColor">
                  Our website is designed with accessibility in mind, ensuring
                  ADA compliance for all users. We are committed to creating an
                  inclusive digital environment, making our content and features
                  fully accessible to individuals with disabilities. Experience
                  a barrier-free browsing experience with us
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="banerSection alignPadding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <div className="bannerImage">
                <figure>
                  <img
                    src={fourthImage}
                    className="img-fluid"
                    alt="Fourth Image"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-5 order-md-first">
              <div className="banerBigtext">
                <h3 className="veryBigFont fontWeightlight">
                  Optimized for{" "}
                  <span className="fontWeightSemiboldLight">
                    ADA compliance & Seamless Mobile{" "}
                  </span>
                  Anytime, Anywhere
                </h3>
                <p className="mt-3 smallFont textColor">
                  Experience our product's full capabilities on the go. Designed
                  for performance and ease of use on your mobile device.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="frequentAskSec">
        <div className="container alignPadding">
          <div className="row align-items-end">
            <div className="col-md-4">
              <div>
                <h3 className="veryBigFont fontWeightlight">
                  Frequently{" "}
                  <span className="fontWeightSemiboldLight d-block">
                    Asked Questions
                  </span>
                </h3>
              </div>
            </div>
            <div className="col-md-3">
              <p className="brightWhite smallMidumFont">
                Check out our frequently asked questions for answers.
              </p>
            </div>
            <div className="col-md-5 text-end">
              <p className="d-inline-block m-0 btnTheme">CONTACT US</p>
            </div>
          </div>
          <div className="row">
            <div
              className="accordion mt-5 myCustomAccordion"
              id="accordionExample"
            >
              {faqs.map((value, index) =>
                value?.show ? (
                  <div
                    className="accordion-item backgroundAdd"
                    onClick={() => toggleShow(index)}
                  >
                    <div
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      <div className="col-1">
                        <h2 className="mt-2 fontWeightSemibold">{index + 1}</h2>
                      </div>
                      <div className="col-9">
                        <h4 className="mt-2 fontWeightSemibold">
                          {value?.question}
                        </h4>
                      </div>
                      <div className="col"></div>
                    </div>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body row m-0">
                        <div className="col-1"></div>
                        <div className="col-9">
                          <p>{value?.answer}</p>
                        </div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="accordion-item backgroundAdd"
                    key={"question" + index}
                    onClick={() => toggleShow(index)}
                  >
                    <div
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapsetwo"
                      aria-expanded="false"
                      aria-controls="collapsetwo"
                    >
                      <div className="col-1">
                        <h2 className="mt-2 fontWeightSemibold">{index + 1}</h2>
                      </div>
                      <div className="col-9">
                        <h4 className="mt-2 fontWeightSemibold">
                          {value?.question}
                        </h4>
                      </div>
                      <div className="col"></div>
                    </div>
                    <div
                      id="collapsetwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="collapsetwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body row m-0">
                        <div className="col-1"></div>
                        <div className="col-9">
                          <p>{value?.answer}</p>
                        </div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="footerMy alignPadding pb-4 deepFotColor">
        <div className="container">
          <div className="row pb-4 align-items-center borderBottom">
            <div className="col-md-3">
              <div className="foterLogo">
                <figure>
                  <img src={logoWhite} className="img-fluid" alt="Logo" />
                </figure>
              </div>
            </div>
            <div className="col-md-9 text-md-end">
              <div className="footerBtnText">
                <h5 className="colorWhite d-inline-block me-3">
                  Ready to get started?
                </h5>
                <p className="d-inline-block m-0 btnTheme">Get started</p>
              </div>
            </div>
          </div>
          <div className="row pt-4 mt-3 colorWhite endFooterSecContent">
            <div className="col-md-6">
              <div className="footerLink">
                <ul className="list-unstyled">
                  <li className="d-inline-block me-4">
                    Made with 💖 from Atlanta
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 text-md-end">
              <div className="footerLink">
                <ul className="list-unstyled">
                  <li className="d-inline-block me-4 cursor-pointer">
                    Terms & Conditions
                  </li>
                  <li className="d-inline-block cursor-pointer">
                    Privacy Policy
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
