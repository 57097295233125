import React from "react";
import { createBrowserRouter, useNavigate } from "react-router-dom";
import { RouterProvider } from "react-router-dom";
import Home from "./components/Home";
import PageNotFoundErrorComponent from "./components/PageNotFoundErrorComponent/PageNotFoundErrorComponent";
import Explore from "./components/Explore";
import Chat from "./components/Chat";
import { useSearchParams } from "react-router-dom";
import CustomIntegration from "./components/CustomIntegration";
const Body = () => {
  const routes = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/explore",
      element: <Explore />,
    },
    {
      path: "/chat",
      element: <Chat />,
    },
    {
      path: "/integration",
      element: <CustomIntegration />,
    },
    { path: "*", element: <PageNotFoundErrorComponent /> },
  ];
  const appRouter = createBrowserRouter(routes);

  return (
    <>
      <RouterProvider router={appRouter} />
    </>
  );
};

export default Body;
