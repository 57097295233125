import Body from "./Body";
import "./asset/css/bootstrap.min.css";
import "./asset/css/all.min.css";
import "./asset/css/owl.carousel.min.css";
import "./asset/css/owl.theme.default.css";
import "./asset/css/main.css";

function App() {
  return (
    <>
      <Body />
    </>
  );
}

export default App;
